import { useState } from 'react';
import './App.css';
import Modal from 'react-modal';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  	const [modalIsOpen, setModalIsOpen] = useState(false);

  	const openModal = () => {
  	  	setModalIsOpen(true);
  	};

  	const closeModal = () => {
  	  	setModalIsOpen(false);
  	};

  	const register = () => {
  	  	const pdfUrl = '/Anmeldung-PDF.pdf';
  	  	window.open(pdfUrl, '_blank');
  	};

  	return (
  	  	<div className='homeView'>
  	  	  	<div className='logoContainer'>
  	  	    	<img className='logoContent' src='/primary-logo.jpg' alt='Primary Logo' />
  	  	    	<button className="styled-button" onClick={register}>Anmeldung</button>
  	  	  	</div>
  	  	  	<div className='homeContent'>
  	  	    	<h3 className='commingSoon'>Hier entsteht die neue Homepage - MagenDarm Zentrum Oberaargau</h3>
  	  	    	<h1 className='title'>NEUERÖFFNUNG im Dezember 2024</h1>
  	  	    	<p className='footer'>MagenDarm Zentrum Oberaargau <br/> Melchnaustrasse 1 <br/> 4900 Langenthal <br/> <a className='footer' href='http://www.mdzo.ch' target='_blank' rel="noreferrer">www.mdzo.ch</a> <br/> <a className='footer' href='mailto:mdzo@hin.ch'>mdzo@hin.ch</a></p>
  	  	    	<p className='description'>Ihr Zentrum im Oberaargau für eine umfassende Abklärung und Behandlung sämtlicher Erkrankungen des Magen-Darm-Traktes (Speiseröhre, Magen, Dünn- und Dickdarm), der Leber, der Gallenwege sowie der Bauchspeicheldrüse.</p>
  	  	    	<p className='description'>Wir freuen uns sehr, ab Dezember 2024, Patienten/Innen im neuen MagenDarm Zentrum Oberaargau, kompetent und sorgfältig beraten und behandeln zu dürfen.</p>
				<p className='description'>Im neuen MagenDarm Zentrum Oberaargau werden wir das gesamte Spektrum der Gastroenterologie und Hepatologie abdecken. Das Angebot umfasst gastroenterologische (inkl. Pankreas- sowie IBD-Sprechstunden mit Infusionstherapien) und hepatologische Sprechstunden, Konsilien, Atemteste mit Frage nach Intoleranzen und Fehlbesiedlungen, Abdomensonografien inkl. Elastografie sowie Endoskopien (Gastroskopie, Ano-Proktoskopie, Sigmoidoskopie, Ileo-Koloskopie und Kapselendoskopie).</p>
  	  	    	<p className='description'>Für Untersuchungen (u.a. Vorsorge-Untersuchungen) ab Dezember 2024, können die Patienten/Innen gerne bereits jetzt per Mail an <a style={{color: '#808185'}} href='mailto:mdzo@hin.ch'>mdzo@hin.ch</a> oder mit dem <span onClick={register} style={{cursor: 'pointer', textDecoration: 'underline'}}>Anmeldeformular</span> (bitte verwenden Sie Adobe Acrobat Reader zum Senden) zugewiesen werden. Wir werden die Patienten/Innen zu gegebenem Zeitpunkt gerne telefonisch kontaktieren und die Untersuchungen ab Dezember 2024 planen.</p>
  	  	    	<p className='description'>Dr. med. Mentor Bilali <br/> Facharzt FMH für Gastroenterologie und Facharzt FMH für Allgemeine Innere Medizin</p>
  	  	  	</div>
  	  	  	{/* <Modal
  	  	  	  	isOpen={modalIsOpen}
  	  	  	  	onRequestClose={closeModal}
  	  	  	  	className="modal-content"
  	  	  	  	overlayClassName="modal-overlay"
  	  	  	>
  	  	  	  	<div className="modal-body">
  	  	  	  	  	<h2>Anmeldung</h2>
  	  	  	  	  	<p>
  	  	  	  	  	  	Abklärungen und Untersuchungen erfolgen in der Regel auf Zuweisung
  	  	  	  	  	  	durch Ihre Hausarztpraxis, eine andere Fachärztin/einen anderen
  	  	  	  	  	  	Facharzt oder nach telefonischer Vereinbarung.
  	  	  	  	  	</p>
  	  	  	  	  	<p>
  	  	  	  	  	  	Ihre Anmeldung nehmen wir gerne per Post, per Fax oder per E-Mail
  	  	  	  	  	  	entgegen. Bei telefonischer Anmeldung bitten wir Sie, die wichtigsten
  	  	  	  	  	  	Angaben schriftlich nachzureichen.
  	  	  	  	  	</p>
  	  	  	  	  	<button className="pdf-button" onClick={register}>
  	  	  	  	  	  	<i className="fas fa-arrow-down" style={{paddingRight: 10}}></i>Anmeldung PDF
  	  	  	  	  	</button>
  	  	  	  	</div>
  	  	  	</Modal> */}
  	  	</div>
  	);
}

export default App;
